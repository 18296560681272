import useOriginClient from '@clarity-website/lib/clients/useOriginClient';
import { useQuery } from '@tanstack/react-query';

/*
  This functionality allows to fetch settings for the feature stored in json file in S3 .

  Assumption is that the file with the feaure-specific name (follows the pattern `${featureName}.json`) exists in the `config/` folder of S3 bucket.
  The name of the bucket containing `config/` folder can be found in CDK configuration file in PiClarityStaticWebsiteCDK package.

  To use this functionality:
  1. Create a json file names in format of `${featureName}.json` with arbitrary structure in `config/` folder of the above-specified S3 bucket.
  2. Describe the structure of the settings in type/interface.
  3. Use `useConfig` hook, specifying type name, and providing feature name to get the settings.

  For example:
    const settings = useConfig<HappinessSettings>("happiness");
*/

export function useConfig<T>(featureName: string) {
  const originClient = useOriginClient();
  return useQuery(['config', featureName], () =>
    originClient.get<T>({ path: `/config/${featureName}.json` }),
  );
}
