import ClientMethods, {
  RestRequestParams,
} from '@clarity-website/lib/clients/ClientMethods';

/**
 * OriginClient is for fetching files from Cloudfront distribution on origin "/"
 */
export default class OriginClient {
  async get<T = any>({
    path,
    init,
    abortController,
  }: RestRequestParams): Promise<T> {
    ClientMethods.validatePath(path);
    return ClientMethods.fetchWithoutAuth<T>({
      input: path,
      init,
      abortController,
    });
  }
  async getWithAuth<T = any>({
    path,
    init,
    abortController,
  }: RestRequestParams): Promise<T> {
    ClientMethods.validatePath(path);
    return ClientMethods.fetch<T>({
      input: path,
      init,
      abortController,
    });
  }
}
