import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@amzn/stencil-react-components/button';
import { Col, Flex, Row } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import { Text } from '@amzn/stencil-react-components/text';
import { useFeatureFlagToggles } from '@clarity-website/app/ConfigurationContext';
import { ReactComponent as ReportTooLargeIcon } from '@clarity-website/assets/svg/report-too-large.svg';
import { useConfig } from '@clarity-website/common/useConfig';
import { UseReportGroupState } from '@clarity-website/reports/ReportPage/useReportGroupState';
import { ReactNode } from 'react';

interface Props {
  openReportFlyout?: () => void;
  exportTableExcel?: () => void;
  reportGroupState?: UseReportGroupState;
}

const SMALL_SCREEN_THRESHOLD = 500;
const MAX_WIDTH_TEXT_SECTION = 290;

export default function ReportTooLargeErrorPage({
  openReportFlyout,
  exportTableExcel,
  reportGroupState,
}: Props) {
  const isSmall = window.innerWidth < SMALL_SCREEN_THRESHOLD;
  const flexDirection = isSmall ? 'column-reverse' : 'row';

  const { isFeatureFlagToggleOnAndOnAllowList: canUseOfflineDownloading } =
    useFeatureFlagToggles('offline-downloading');

  const { data: { rtoNonEditable } = {} } = useConfig<{
    rtoNonEditable: { message: string; link: string };
  }>('copy/errorPage');

  const reportGroupFeatures =
    reportGroupState?.value.initialReportGroup.settings?.features;
  const isRTONonEditable =
    reportGroupFeatures?.includes('rto') &&
    reportGroupFeatures?.includes('page0');

  function rtoNonEditableErrorMsg() {
    return (
      <>
        {rtoNonEditable?.message}
        <br />
        <Link
          href={rtoNonEditable?.link || ''}
          aria-label="Open RTO Badge Report Deep Dive (opens in new tab)"
          target="_blank"
          rel="noopener noreferrer"
        >
          RTO Badge Report - Deep Dive
        </Link>
      </>
    );
  }

  // Temporary
  let text: ReactNode = 'Add some filters to reduce its size';
  if (canUseOfflineDownloading) {
    text += ', or get a link to download to Excel';
  }
  if (isRTONonEditable) {
    text = rtoNonEditableErrorMsg();
  }

  return (
    <Flex alignItems="center" gridGap="S300" flexDirection={flexDirection}>
      <Col gridGap="S300" maxWidth={MAX_WIDTH_TEXT_SECTION}>
        <Text fontSize="T300">This report is quite large</Text>
        <Text fontSize="T100">{text}</Text>
        {!isRTONonEditable && (
          <Row columnGap="S300">
            <Button
              onClick={openReportFlyout}
              variant={ButtonVariant.Primary}
              size={ButtonSize.Small}
            >
              Add filters
            </Button>
            {canUseOfflineDownloading && (
              <Button onClick={exportTableExcel} size={ButtonSize.Small}>
                Get download link
              </Button>
            )}
          </Row>
        )}
      </Col>
      <Col alignItems="center">
        <ReportTooLargeIcon />
      </Col>
    </Flex>
  );
}
